@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
}
.swiper-button-next,
.swiper-button-prev {
  opacity: 1 !important; /* Her zaman görünür */
  color: #fff; /* Ok rengini beyaz yapar */
  border-radius: 50%; /* Yuvarlak görünüm */
  width: 40px;
  height: 40px;
  
}

/* Oklar hover olduğunda daha belirgin hale gelir */
.swiper-button-next:hover,
.swiper-button-prev:hover {
  transform: scale(1.2); /* Hafif büyüme efekti */
}
/* .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
   */
/* styles.css */

.swiper-button-prev,
.swiper-button-next {
  overflow: hidden !important;
  opacity: 0;
}

.content {
  padding: 24px;
  /* İçeriği düzenlemek için padding ekle */
  border: none !important;
  /* İçerik divinin kenarını kaldır */
  box-shadow: none !important;
  /* İçerik divinin gölgesini kaldır */
  background-color: #fff;
  /* İstersen arka plan rengini ayarlayabilirsin */
}

.ant-modal-content {
  padding: 0px !important;
  border-radius: 16px !important;
}

.special-login {
  width: 800px !important;
  height: 520px !important;
  border-radius: 16px !important;
  padding: 0px 0px !important;
}

/* .special-register {
    width: 1056px !important;
    height: 594px !important;
    border-radius: 16px !important;
    padding: 0px 0px !important;
   } */

.custom-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #E4E4E7;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FFEB00;
  /* Sarı arka plan */
  border-color: #FFEB00;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #09090B;
  /* Siyah tik işareti */
}

.custom-modal .ant-modal-header {
  padding: 12px;
  /* Başlık kısmına padding */
  margin-bottom: 0px !important;
}


.custom-skeleton-img {
  width: 100% !important;
  display: block !important;
  margin: 0 auto !important;
}


.ant-skeleton.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
}

.ant-skeleton.ant-skeleton-element .ant-skeleton-image .ant-skeleton-image-svg {
  display: block;
  height: 100%;
  padding-top: 40px;
  margin: 0 auto;
}


#filter-select .ant-select-selector {
  border: none; /* Remove any existing borders */
  border-bottom: 1px solid #18181C; /* Add a bottom border (adjust color and thickness as needed) */
}